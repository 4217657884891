<template>
	<div style="padding-bottom: 10px;">
		<el-form :inline="true" :model="form" class="demo-form-inline">
			<el-form-item label="日期">
				<el-date-picker v-model="form.searchDate" type="date" placeholder="选择预约日期"></el-date-picker>
			</el-form-item>
			<el-form-item >
				<el-select v-model="form.status" placeholder="请选择上下午">
					<el-option label="全部" value="" seleted></el-option>
					<el-option label="上午" value="1"></el-option>
					<el-option label="下午" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="">
				<el-input v-model="form.searchValue" placeholder="预约者手机号"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="danger" icon="el-icon-search" @click="doSearch">查询</el-button>
			</el-form-item>
			<el-form-item style="margin-left: 50px;">
				<el-button type="info" icon="el-icon-warning-outline" @click="handleForbid()">禁用预约</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="tableData" border style="width: 100%" :row-class-name="tableRowClassName">
			<el-table-column prop="borrowername" label="预约人姓名" width="110px"></el-table-column>
			<el-table-column prop="phone" label="预约人电话" width="170px"></el-table-column>
			<el-table-column prop="borrowertime" label="预约时间" width="170px">
				<template slot-scope="scope">
					{{scope.row.borrowertime}} {{scope.row.ampm>1?"下午":"上午"}}
				</template>
			</el-table-column>
			<el-table-column label="医生照片" width="80px">
				<template slot-scope="scope">
					<img :src="scope.row.docImg" @click="viewImg(scope.row.docImg)" height="40px" />
				</template>
			</el-table-column>
			<el-table-column prop="docName" label="医生姓名" width="100px"></el-table-column>
			<el-table-column label="预约状态" width="80px">
				<template slot-scope="scope">
					{{scope.row.status>1?"已取消":"已预约"}}
				</template>
			</el-table-column>
			<el-table-column prop="remark" label="备注"></el-table-column>
			<el-table-column label="操作" width="130px">
				<template slot-scope="scope">
					<el-button @click="handleDisable(scope.row)" type="text" size="small">禁用</el-button>
					<el-button v-if="scope.row.status==1" @click="handleCancle(scope.row)" type="text" size="small">取消</el-button>&nbsp;
					<el-button @click="handleDelete(scope.row)" type="text" size="small">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="padding-top: 10px;">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[10,15,20,25,30]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="totalSize">
			</el-pagination>
		</div>
		
		<el-dialog title="禁用预约用户功能" width="500px" :visible.sync="dialogForbidVisible" append-to-body>
			<el-form :inline="true" :model="form" class="demo-form-inline">
				<el-form-item label="">
					<el-input style="width:280px" v-model="forbidPhone" placeholder="请输入预约者手机号"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="danger" icon="el-icon-warning-outline" @click="doForbid">禁用</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog width="600px" title="医生照片大图" :visible.sync="dialogImage" append-to-body>
			<img :src="imageUrl" width="100%" />
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'yuyuelist',
	components: {
		//
	},
	props: [],
	data() {
		return {
			currentPage: 1,
			pageSize: 10,
			totalPage: 1,
			totalSize: 1,
			form: {
				searchValue: '',
				status: '',
				searchDate: ''
			},
			
			tableData: [],
			forbidPhone: '',
			dialogForbidVisible: false,
			dialogImage: false,
			imageUrl: '',
		};
	},
	mounted() {
		this.loadData();
	},
	methods: {
		viewImg(imgUrl){
			this.imageUrl = imgUrl;
			this.dialogImage = true;
		},
		getDateStr(val, _short){
			if(!val||val==""){
				return '';
			}
			let d = new Date(val);
			let year = d.getFullYear();
			let month = d.getMonth() + 1;
			month = month<10 ? '0'+month : month;
			let day = d.getDate();
			day = day<10 ? '0'+day : day;
			let hour = d.getHours();
			hour = hour<10 ? '0'+hour : hour;
			let minu = d.getMinutes();
			minu = minu<10 ? '0'+minu : minu;
			let sec = d.getSeconds();
			sec = sec<10 ? '0'+sec : sec;
			if(_short){
				return year+"-"+month+"-"+day;
			}
			return year+"-"+month+"-"+day+" "+hour+":"+minu+":"+sec;
		},
		tableRowClassName({row}) {
			if (row.status == 2) {
				return 'reject-row';
			} 
			return '';
		},
		doSearch(){
			this.currentPage=1;
			this.loadData();
		},
		loadData(){
			let _query = [
				"currentPage="+this.currentPage,
				"pageSize="+this.pageSize,
				"phone="+this.form.searchValue,
				"ampm="+this.form.status,
				"date="+ this.getDateStr(this.form.searchDate, true)
			].join("&")
			this.$axios.get('/records/getlist?'+_query).then(res => {
				// console.log(res);
				if(res.status==200&&res.data.code==200){
					this.totalPage = res.data.data.totalPage;
					this.totalSize = res.data.data.totalSize;
					this.tableData = res.data.data.list;
				}
			});
		},
		handleSizeChange(val){
			this.pageSize = val;
			this.loadData();
		},
		handleCurrentChange(val){
			this.currentPage =val;
			this.loadData();
		},
		handleDelete(row){
			let _this = this;
			this.$confirm('确定要删除“'+row.borrowername+' '+row.borrowertime+' '+(row.ampm>1?"上午":"下午")+'”的预约吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios.delete('/records?id='+row.id).then(res => {
					if(res.status==200&&res.data.code==200){
						_this.$message({
							type: 'success',
							message: '删除成功!'
						});
						_this.loadData();
					}else{
						_this.$message({
							type: 'warning',
							message: '删除失败!'
						});
					}
				});
			}).catch(() => {
				//
			});
		},
		handleCancle(row){
			let _this = this;
			this.$confirm('确定要取消“'+row.borrowername+' '+row.borrowertime+' '+(row.ampm>1?"上午":"下午")+'”的预约吗?', '提示', {
				confirmButtonText: '确定取消',
				cancelButtonText: '放弃',
				type: 'warning'
			}).then(() => {
				this.$axios.post('/records/cancel',{id:row.id}).then(res => {
					if(res.status==200&&res.data.code==200){
						_this.$message({
							type: 'success',
							message: '取消成功!'
						});
						_this.loadData();
					}else{
						_this.$message({
							type: 'warning',
							message: '取消失败!'
						});
					}
				});
			}).catch(() => {
				//
			});
		},
		
		handleForbid(){
			this.forbidPhone = this.form.searchValue==''? '' : this.form.searchValue;
			this.dialogForbidVisible = true;
		},
		handleDisable(row){
			this.forbidPhone = row.phone;
			this.dialogForbidVisible = true;
		},
		doForbid(){
			this.$axios.post('/users/ban',{phone:this.forbidPhone}).then(res => {
				if(res.status==200&&res.data.code==200){
					this.$message({
						type: 'success',
						message: '禁用成功!'
					});
					this.forbidPhone = '';
					this.dialogForbidVisible = false;
					this.loadData();
				}else{
					this.$message({
						type: 'warning',
						message: '禁用失败!'
					});
				}
			}).catch(()=>{
				this.$message({
					type: 'warning',
					message: '禁用失败!'
				});
			});
		}
	}
}
</script>

<style>
	.el-table .reject-row {
		background: #ffbaba;
	}
	.el-table .success-row {
		background: #f0fde9;
	}
	.table-expand {
		font-size: 0;
	}
	.table-expand label {
		width: 90px;
		color: #99a9bf;
	}
	.table-expand .el-form-item {
		margin-right: 0;
		margin-bottom: 0;
		width: 50%;
	}
	.el-table__expanded-cell{background-color:#f5f7fa;}
	
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 150px;
		height: 150px;
		line-height: 150px;
		text-align: center;
	}
	.avatar {
		width: 150px;
		height: 150px;
		display: block;
	}
</style>
