<template>
	<el-container>
		<el-aside width="240px">
			<cmenu :menu-index="1" />
		</el-aside>
		<el-container>
			<el-header>
				<uinfo />
			</el-header>
			<el-main :style="{height: main_height}">
				<div class="card">
					<div class="con">
						<div class="con_title">
							<div class="con_line"></div>
							<div class="con_text">预约管理</div>
						</div>
					</div>
					<el-divider></el-divider>
					<div class="">
						<yuyuelist />
					</div>
				</div>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
import cmenu from '../components/menu.vue'
import uinfo from '../components/uinfo.vue'
import yuyuelist from '../components/yuyuelist.vue'
export default {
	name: 'yuyue',
	components: {
		cmenu,uinfo,yuyuelist
	},
	props: [],
	data() {
		return {
			main_height: '500px'
		};
	},
	mounted() {
		this.main_height = (window.innerHeight-60)+"px";
	},
	methods: {
		//
	}
}
</script>

<style scoped>

</style>
